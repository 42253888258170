<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Staff Affairs</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Staff-Affairs' }"
              >Staff Affairs</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Staff-Affairs-Staff' }"
              >Staff</router-link
            >
          </li>
          <li class="breadcrumb-item active">View</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Staff Details</h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Code</label>
                <label class="col-md-9 col-form-label">
                  {{ staff.code }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Name</label>
                <label class="col-md-9 col-form-label">
                  {{ staff.fullname }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Email</label>
                <label class="col-md-9 col-form-label">
                  {{ staff.email }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Gender</label>
                <label class="col-md-9 col-form-label">
                  {{ staff.gender }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Phone</label>
                <label class="col-md-9 col-form-label">
                  {{ staff.phone }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Role</label>
                <label class="col-md-9 col-form-label">
                  <span v-for="(role, i) in staff.rolesNames" :key="i">
                    {{ role }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useStaff from "@/composables/staff";
import { inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const { getSingleStaff, staff } = useStaff();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("view_staff"))
        router.push({
          name: "401",
        });

      await getSingleStaff(props.id);
    });

    return {
      staff,
    };
  },
};
</script>
